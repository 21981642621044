import ALIPAY from "@/assets/trade/Alipay.png";
import AUPAY from "@/assets/trade/aupay.png";
import BANKITPAY from "@/assets/trade/bankit-pay.png";
import BANKPAY from "@/assets/trade/bankpay.png";
import DPAY from "@/assets/trade/dpay.png";
import GINKOPAY from "@/assets/trade/GINKO-pay.png";
import GLNPAY from "@/assets/trade/GLNpay.png";
import JCOINPAY from "@/assets/trade/Jcoin.png";
import JKOPAY from "@/assets/trade/Jkopay.png";
import LINEPAY from "@/assets/trade/linepay.png";
import MERPAY from "@/assets/trade/merpay.png";
import WECHATPAY from "@/assets/trade/micropay.png";
import ORIGAMIPAY from "@/assets/trade/Origamipay.png";
import PAYPAY from "@/assets/trade/paypay.png";
import QUOPAY from "@/assets/trade/QUO.png";
import RAKUTENPAY from "@/assets/trade/rakuten-pay.png";
import SMARTCODE from "@/assets/trade/SmartCode.png";
import UNIONPAY from "@/assets/trade/unionpay.png";
import VERITRANSCARD from "@/assets/trade/Veritranscard.png";
import MOBILEPAY from "@/assets/trade/mobilepay.png";
import STARPAY from "@/assets/trade/StarPay.png";
import FAMIPAY from "@/assets/trade/famipay.png";
import ATONEPAY from "@/assets/trade/atone.png";
import BLUECODEPAY from "@/assets/trade/Bluecode.png";
import AFTEEPAY from "@/assets/trade/AFTEE.png";
import SonyPayment from "@/assets/trade/Sony-Payment.png";
import sonypay from "@/assets/trade/Sony-Payment.png";
import _2C2P from "@/assets/trade/2c2p.png";
import _2c2p from "@/assets/2c2p.png";
import paypay from "@/assets/paypay.png";
import dpay from "@/assets/dpay.png";
import aupay from "@/assets/aupay.png";
import linepay from "@/assets/linepay.png";
import wechatpay from "@/assets/wechatpay.png";
import rakutenpay from "@/assets/rakutenpay.png";
import unionpay from "@/assets/unionpay.png";
import quopay from "@/assets/quo.png";
import jcoinpay from "@/assets/jcoin.png";
import glnpay from "@/assets/glnpay.png";
import jkopay from "@/assets/jkopay.png";
import smartcode from "@/assets/smartcode.png";
import bankpay from "@/assets/bankpay.png";
import afteepay from "@/assets/aftee.png";
import atonepay from "@/assets/atone.png";
import famipay from "@/assets/famipay.png";
import veritranscard from "@/assets/veritranscard.png";
import bankitpay from "@/assets/bankitpay.png";
import alipay from "@/assets/alipay.png";
import origamipay from "@/assets/origamipay.png";
import merpay from "@/assets/merpay.png";
import mobilepay from "@/assets/mobilepay.png";
import bluecodepay from "@/assets/Bluecode.png";
import starpay from "@/assets/starpay.png";
import ginkopay from "@/assets/GINKO-pay.png";
import EGIFTEE from "@/assets/trade/EGIFTEE.png";
import egiftee from "@/assets/EGIFTEE.png";
import PAYPAL from "@/assets/trade/PayPal.png";
import paypal from "@/assets/PayPal.png";
import PIVOPAY from "@/assets/trade/Pivo.png";
import pivopay from "@/assets/Pivo.png";
import MBBANKPAY from "@/assets/trade/MBBank.png";
import mbbankpay from "@/assets/MBBank.png";
import WINGPAY from "@/assets/trade/WingPay.png";
import wingpay from "@/assets/WingPay.png";
import TRUEMONEY from "@/assets/trade/TrueMoney.png";
import truemoney from "@/assets/TrueMoney.png";
import SBERBANKPAY from "@/assets/trade/Sberbank.png";
import sberbankpay from "@/assets/Sberbank.png";
import KBZBANKPAY from "@/assets/trade/KBZ-Bank.png";
import kbzbankpay from "@/assets/KBZ-Bank.png";
import BKMEXSPAY from "@/assets/trade/BKM-express.png";
import bkmexspay from "@/assets/BKM-express.png";
import SWISHPAY from "@/assets/trade/Swish.png";
import swishpay from "@/assets/Swish.png";
import GEAPAY from "@/assets/trade/GEA.png";
import geapay from "@/assets/GEA.png";
import MONEYTAP from "@/assets/trade/Money-Tap.png";
import moneytap from "@/assets/Money-Tap.png";
import HAMAPAY from "@/assets/trade/hamapay.png";
import hamapay from "@/assets/hamapay.png";
import NISSENREN from "@/assets/trade/nissenren.png";
import nissenren from "@/assets/nissenren.png";
import NSPOINT from "@/assets/trade/point.png";
import nspoint from "@/assets/point.png";
import NANACOFC from "@/assets/trade/NANACOFC.png";
import nanacofc from "@/assets/NANACOFC.png";
import RKTEDYFC from "@/assets/trade/RKTEDYFC.png";
import rktedyfc from "@/assets/RKTEDYFC.png";
import ICFC from "@/assets/trade/ICFC.png";
import icfc from "@/assets/ICFC.png";
import IDFC from "@/assets/trade/IDFC.png";
import idfc from "@/assets/IDFC.png";
import QUICPAYFC from "@/assets/trade/QUICPAYFC.png";
import quicpayfc from "@/assets/QUICPAYFC.png";
import WAONFC from "@/assets/trade/WAONFC.png";
import waonfc from "@/assets/WAONFC.png";
import SAPICAFC from "@/assets/trade/SAPICAFC.png";
import sapicafc from "@/assets/SAPICAFC.png";
import SPPREMIUM from "@/assets/trade/SPPREMIUM.png";
import sppremium from "@/assets/SPPREMIUM.png";
import DPOINT from "@/assets/trade/dpoint.png";
import dpoint from "@/assets/dpoint.png";
import CASH from "@/assets/trade/cash.png";
import cash from "@/assets/cash.png";
import POINTLIMIT from "@/assets/trade/pointlimit.png";
import pointlimit from "@/assets/pointlimit.png";
import PROMPTPAY from "@/assets/trade/promptpay.png";
import promptpay from "@/assets/promptpay.png";
import ALIPAYPLUS from "@/assets/trade/alipayplus.png";
import alipayplus from "@/assets/alipayplus.png";
import AEONPAY from "@/assets/trade/AEONpay.png";
import aeonpay from "@/assets/AEONpay.png";
import GOTOEAT from "@/assets/trade/GotoEatoki.png";
import gotoeat from "@/assets/GotoEatoki.png";
import UPICARDPOS from "@/assets/trade/UPICARDPOS.png";
import upicardpos from "@/assets/UPICARDPOS.png";
import BOKISPQ from "@/assets/trade/bokispq.png";
import bokispq from "@/assets/bokispq.png";
import EZOCA from "@/assets/trade/ezoca.png";
import ezoca from "@/assets/ezoca.png";
import COGCA from "@/assets/trade/CoGCa.png";
import cogca from "@/assets/CoGCa.png";
//230629增加ponta积分
import PONTAPOINT from "@/assets/trade/PONTAPOINT_60x38.png";
import pontapoint from "@/assets/PONTAPOINT_200x120.png";
//230724增加sppnss
import SPPNSS from "@/assets/trade/SPPNSS_60x38.png";
import sppnss from "@/assets/SPPNSS_200x120.png";
//240105增加 NCPI
import NCPI from "@/assets/trade/NCPI_60x38.png";
import ncpi from "@/assets/NCPI_200x120.png";

//240112 调整 logo
//大字母代表大 logo
import VISA from "@/assets/VISA_200x120.jpg";
import visa from "@/assets/trade/VISA_60x36.jpg";
import MASTERCARD from "@/assets/MASTERCARD_200x120.jpg";
import mastercard from "@/assets/trade/MASTERCARD_60x36.jpg";
import JCB from "@/assets/JCB_200x120.jpg";
import jcb from "@/assets/trade/JCB_60x36.jpg";
import AMEX from "@/assets/AMEX_200x120.jpg";
import amex from "@/assets/trade/AMEX_60x36.jpg";
import DISCOVER from "@/assets/DISCOVER_200x120.jpg";
import discover from "@/assets/trade/DISCOVER_60x36.jpg";
import DINERS from "@/assets/DINERS_200x120.jpg";
import diners from "@/assets/trade/DINERS_60x36.jpg";

//240701拆分sonylogo
import CREDITOM from "@/assets/CREDITOM_200x120.png";
import creditom from "@/assets/trade/CREDITOM_60x36.png";
//根据支付类型获取logo
function getPayLogoByTradeType(payType) {
  let payLogo = "";
  switch (payType) {
    case "COGCA":
    case "COGCAFC":
      payLogo = COGCA;
      break;
    case "DISCOVER":
    case "DISCOVERPOS":
      payLogo = discover;
      break;
    case "DINERS":
    case "DINERSPOS":
      payLogo = diners;
      break;
    case "AMEX":
    case "AMEXPOS":
      payLogo = amex;
      break;
    case "JCB":
    case "JCBPOS":
    case "JCBOM":
    case "BJCBOM":
      payLogo = jcb;
      break;
    case "UPICARD":
    case "UPICARDPOS":
      payLogo = UPICARDPOS;
      break;
    case "PROMPTPAY":
      payLogo = PROMPTPAY;
      break;
    case "POINTLIMIT":
      payLogo = POINTLIMIT;
      break;
    case "EXTTRADE":
      payLogo = CASH;
      break;
    case "EZOPOINTGIVE":
    case "EZOPOINTUSE":
    case "EZOPOINT":
      payLogo = EZOCA;
      break;
    case "DPOINTGIVE":
    case "DPOINTUSE":
    case "DPOINT":
      payLogo = DPOINT;
      break;
    case "SPPREMIUM":
      payLogo = SPPREMIUM;
      break;
    case "SPPBOKI":
    case "SPPBOKICPM":
    case "SPPBOKIMPM":
      payLogo = BOKISPQ;
      break;
    case "NANACO":
    case "NANACOFC":
      payLogo = NANACOFC;
      break;
    case "RKTEDY":
    case "RKTEDYFC":
      payLogo = RKTEDYFC;
      break;
    case "IC":
    case "ICFC":
      payLogo = ICFC;
      break;
    case "ID":
    case "IDFC":
      payLogo = IDFC;
      break;
    case "QUICPAY":
    case "QUICPAYFC":
      payLogo = QUICPAYFC;
      break;
    case "WAONFC":
    case "WAON":
      payLogo = WAONFC;
      break;
    case "SAPICA":
    case "SAPICAFC":
      payLogo = SAPICAFC;
      break;
    case "NSPOINT":
      payLogo = NSPOINT;
      break;
    case "NISSENREN":
      payLogo = NISSENREN;
      break;
    case "VISAOM":
    case "BVISAOM":
    case "VISAPOS":
    case "VISA":
      payLogo = visa;
      break;
    case "MASTERCARDOM":
    case "BMASTERCARDOM":
    case "MASTERCARDPOS":
    case "MASTERCARD":
      payLogo = mastercard;
      break;
    case "MONEYTAP":
      payLogo = MONEYTAP;
      break;
    case "GEAPAY":
      payLogo = GEAPAY;
      break;
    case "SWISHPAY":
      payLogo = SWISHPAY;
      break;
    case "BKMEXSPAY":
      payLogo = BKMEXSPAY;
      break;
    case "KBZBANKPAY":
      payLogo = KBZBANKPAY;
      break;
    case "SBERBANKPAY":
      payLogo = SBERBANKPAY;
      break;
    case "TRUEMONEY":
      payLogo = TRUEMONEY;
      break;
    case "WINGPAY":
      payLogo = WINGPAY;
      break;
    case "MBBANKPAY":
    case "MBBANKMPM":
      payLogo = MBBANKPAY;
      break;
    case "PIVOPAY":
      payLogo = PIVOPAY;
      break;
    case "EGIFTEE":
      payLogo = EGIFTEE;
      break;
    case "GOTOEAT":
      payLogo = GOTOEAT;
      break;
    case "PAYPALOM":
    case "PAYPAL":
      payLogo = PAYPAL;
      break;
    case "ESCOTTPAY":
    case "CAREEROM":
    case "CVSLIPOM":
    //240703 增加兼容 GWType case "ESCOTTCVSLIP":
    //GWTYPE ESCOTTCVSLIP = TRADETYPE CVSLIPOM
    case "ESCOTTCVSLIP":
      //end
      payLogo = SonyPayment;
      break;
    case "2C2PPAY":
    case "2C2PPAYMPM":
      payLogo = _2C2P;
      break;
    case "ALQR":
    case "ALIPAY":
    case "ALMOB":
    case "ALMINIPRO":
    case "ALOM":
      payLogo = ALIPAYPLUS;
      break;
    case "PAYPAY":
    case "PAYPAYMPM":
    case "PAYPAYOM":
    case "PAYPAYMINI":
    case "PAYPAYSKU":
      payLogo = PAYPAY;
      break;
    case "DPAY":
    case "DPAYMPM":
    case "DPAYOM":
    case "DPAYMINIPRO":
    case "DPAYTDOM":
    case "DPAYAPP":
      payLogo = DPAY;
      break;
    case "AUPAY":
    case "AUPAYMPM":
    case "AUPAYOM":
    case "AUPAYNET":
      payLogo = AUPAY;
      break;
    case "LINEPAY":
    case "LNPAY":
      payLogo = LINEPAY;
      break;
    case "MICROPAY":
    case "WECHATPAY":
    case "NATIVE":
    case "JSAPI":
    case "MINIPRO":
      payLogo = WECHATPAY;
      break;
    case "RAKUTENPAY":
    case "RKTPAY":
    case "RKTGAS":
    case "RKTPAYOM":
      payLogo = RAKUTENPAY;
      break;
    case "UPI":
    case "UPICPM":
    case "UPIMPM":
    case "UNIONPAY":
    case "UPIOM":
      payLogo = UNIONPAY;
      break;
    case "QUOPAY":
      payLogo = QUOPAY;
      break;
    case "JCOINPAY":
    case "JCOINMPM":
      payLogo = JCOINPAY;
      break;
    case "GLNPAY":
    case "GLNMPM":
      payLogo = GLNPAY;
      break;
    case "JKOPAY":
      payLogo = JKOPAY;
      break;
    case "BANKIT":
      payLogo = BANKITPAY;
      break;
    case "SMARTCODE":
      payLogo = SMARTCODE;
      break;
    case "BANKPAY":
      payLogo = BANKPAY;
      break;
    case "ORIGAMIPAY":
      payLogo = ORIGAMIPAY;
      break;
    case "GINKOPAY":
      payLogo = GINKOPAY;
      break;
    case "MERPAY":
    case "MERPAYOM":
      payLogo = MERPAY;
      break;
    case "VERITRANSCARD":
      payLogo = VERITRANSCARD;
      break;
    case "MOBILEPAYMPM":
      payLogo = MOBILEPAY;
      break;
    case "FAMIPAY":
      payLogo = FAMIPAY;
      break;
    case "ATONEPAY":
      payLogo = ATONEPAY;
      break;
    case "BLUECODEPAY":
      payLogo = BLUECODEPAY;
      break;
    case "AFTEEPAY":
      payLogo = AFTEEPAY;
      break;
    case "AEONPAY":
    case "AEONMPM":
      payLogo = AEONPAY;
      break;
    case "HAMAPAY":
    case "HAMAPAYOM":
      payLogo = HAMAPAY;
      break;
    //230629增加PONTAPOINT
    case "PONTAPOINT":
    case "PONTAPOINTUSE":
    case "PONTAPOINTGIVE":
      payLogo = PONTAPOINT;
      break;
    //end
    //230724增加SPPNSS
    case "SPPNSS":
    case "SPPNSSCPM":
    case "SPPNSSMPM":
      payLogo = SPPNSS;
      break;
    //end
    //240105增加NCPI
    case "NPCIQNBMPM":
    case "NPCIPAY":
    case "NPCI":
      payLogo = NCPI;
      break;
    //end
    //240701拆分sonylogo
    case "ESCOTTCARD":
    case "CREDITOM":
      payLogo = CREDITOM;
      break;
    //end
    default:
      payLogo = STARPAY;
      break;
  }
  return payLogo;
}

//根据支付类型名称获取大logo
function getBigPayLogoByTradeTypeName(payType) {
  let payLogo = "";
  switch (payType) {
    case "CoGCa":
      payLogo = cogca;
      break;
    case "Discover Card":
      payLogo = DISCOVER;
      break;
    case "Diners Club International":
      payLogo = DINERS;
      break;
    case "American Express":
      payLogo = AMEX;
      break;
    case "JCB":
      payLogo = JCB;
      break;
    case "银联卡":
    case "銀聯":
      payLogo = upicardpos;
      break;
    case "PromptPay":
      payLogo = promptpay;
      break;
    case "POINTLIMIT":
      payLogo = pointlimit;
      break;
    case "現金及びその他":
      payLogo = cash;
      break;
    case "dポイント":
    case "dポイント[利用][進呈]":
    case "dポイント[利用]":
    case "dポイント[進呈]":
      payLogo = dpoint;
      break;
    case "EZOCA ポイント":
    case "EZOCA ポイント利用":
    case "EZOCA ポイント進呈":
      payLogo = ezoca;
      break;
    case "プレミアム商品券(RM)":
    case "プレミアム商品券":
      payLogo = sppremium;
      break;
    case "プレミアム商品券(冲绳)":
    case "プレミアム商品券(CPM)":
    case "プレミアム商品券(MPM)":
      payLogo = bokispq;
      break;
    case "nanaco":
      payLogo = nanacofc;
      break;
    case "楽天Edy":
      payLogo = rktedyfc;
      break;
    case "交通系IC":
      payLogo = icfc;
      break;
    case "iD":
      payLogo = idfc;
      break;
    case "QUICPay":
    case "QUICPay+":
      payLogo = quicpayfc;
      break;
    case "WAON":
      payLogo = waonfc;
      break;
    case "SAPICA":
      payLogo = sapicafc;
      break;
    case "ポイント":
      payLogo = nspoint;
      break;
    case "日専連":
      payLogo = nissenren;
      break;
    case "はまPay":
      payLogo = hamapay;
      break;
    case "Visa":
      payLogo = VISA;
      break;
    case "MasterCard":
      payLogo = MASTERCARD;
      break;
    case "Sony Payment":
    case "ソニーペイキャリア":
    case "ソニーペイコンビニ":
    //240703 兼容コンビニ決済
    case "コンビニ決済":
      //end
      payLogo = sonypay;
      break;
    case "PayPal":
      payLogo = paypal;
      break;
    case "e街ギフト":
      payLogo = egiftee;
      break;
    case "GoToEat":
      payLogo = gotoeat;
      break;
    case "Money Tap":
      payLogo = moneytap;
      break;
    case "GEA":
      payLogo = geapay;
      break;
    case "Swish":
      payLogo = swishpay;
      break;
    case "BKM express":
      payLogo = bkmexspay;
      break;
    case "KBZ Bank":
      payLogo = kbzbankpay;
      break;
    case "Sberbank":
      payLogo = sberbankpay;
      break;
    case "TrueMoney":
      payLogo = truemoney;
      break;
    case "WingPay":
      payLogo = wingpay;
      break;
    case "MBBank":
      payLogo = mbbankpay;
      break;
    case "2c2p":
      payLogo = _2c2p;
      break;
    case "Pivo":
      payLogo = pivopay;
      break;
    case "Alipay":
      payLogo = alipayplus;
      break;
    case "PayPay":
      payLogo = paypay;
      break;
    case "d払い":
      payLogo = dpay;
      break;
    case "au PAY":
      payLogo = aupay;
      break;
    case "LINE Pay":
      payLogo = linepay;
      break;
    case "WeChat Pay":
      payLogo = wechatpay;
      break;
    case "楽天ペイ":
      payLogo = rakutenpay;
      break;
    case "銀聯QRコード決済":
      payLogo = unionpay;
      break;
    case "QUOカードPay":
      payLogo = quopay;
      break;
    case "J-Coin Pay":
      payLogo = jcoinpay;
      break;
    case "GLN":
      payLogo = glnpay;
      break;
    case "JKOPAY":
      payLogo = jkopay;
      break;
    case "BANKIT":
      payLogo = bankitpay;
      break;
    case "Smart Code":
      payLogo = smartcode;
      break;
    case "Bank Pay":
      payLogo = bankpay;
      break;
    case "AFTEEPAY":
      payLogo = afteepay;
      break;
    case "atonePay":
    case "atone":
      payLogo = atonepay;
      break;
    case "AeonPay":
    case "AEON Pay":
      payLogo = aeonpay;
      break;
    case "FamiPay":
      payLogo = famipay;
      break;
    case "VERITRANSCARD":
      payLogo = veritranscard;
      break;
    case "VeriTrans":
      payLogo = veritranscard;
      break;
    case "UnionCard":
      payLogo = unionpay;
      break;
    case "Origami Pay":
      payLogo = origamipay;
      break;
    case "メルペイ":
      payLogo = merpay;
      break;
    case "MobilePay":
      payLogo = mobilepay;
      break;
    case "BLUECODEPAY":
      payLogo = bluecodepay;
      break;
    case "Bluecode":
      payLogo = bluecodepay;
      break;
    case "銀行Pay":
    case "OKIPAY":
      payLogo = ginkopay;
      break;
    //230629增加POINT积分
    case "Ponta ポイント":
      payLogo = pontapoint;
      break;
    //end
    //230724增加SPPNSS
    case "残高払い":
      payLogo = sppnss;
      break;
    //end
    //240105增加NCPI
    case "NPCIQNBMPM":
    case "NPCIPAY":
    case "NPCI":
      payLogo = ncpi;
      break;
    //end
    //240701拆分sonylogo
    case "ソニーペイクレジットカード":
      payLogo = creditom;
      break;
    //end
    default:
      payLogo = starpay;
  }
  return payLogo;
}
//根据支付类型名称获取常规大小logo
function getNormalPayLogoByTradeTypeName(payType) {
  let payLogo = "";
  switch (payType) {
    case "CoGCa":
      payLogo = COGCA;
      break;
    case "Discover Card":
      payLogo = DISCOVER;
      break;
    case "Diners Club International":
      payLogo = DINERS;
      break;
    case "American Express":
      payLogo = AMEX;
      break;
    case "JCB":
      payLogo = JCB;
      break;
    case "银联卡":
    case "銀聯":
      payLogo = UPICARDPOS;
      break;
    case "PromptPay":
      payLogo = PROMPTPAY;
      break;
    case "POINTLIMIT":
      payLogo = POINTLIMIT;
      break;
    case "現金及びその他":
      payLogo = CASH;
      break;
    case "EZOCA ポイント":
    case "EZOCA ポイント利用":
    case "EZOCA ポイント進呈":
      payLogo = EZOCA;
      break;
    case "dポイント":
    case "dポイント[利用][進呈]":
    case "dポイント[利用]":
    case "dポイント[進呈]":
      payLogo = DPOINT;
      break;
    case "プレミアム商品券":
      payLogo = SPPREMIUM;
      break;
    case "プレミアム商品券(CPM)":
    case "プレミアム商品券(MPM)":
      payLogo = BOKISPQ;
      break;
    case "nanaco":
      payLogo = NANACOFC;
      break;
    case "楽天Edy":
      payLogo = RKTEDYFC;
      break;
    case "交通系IC":
      payLogo = ICFC;
      break;
    case "iD":
      payLogo = IDFC;
      break;
    case "QUICPay":
    case "QUICPay+":
      payLogo = QUICPAYFC;
      break;
    case "WAON":
      payLogo = WAONFC;
      break;
    case "SAPICA":
      payLogo = SAPICAFC;
      break;
    case "ポイント":
      payLogo = NSPOINT;
      break;
    case "日専連":
      payLogo = NISSENREN;
      break;
    case "はまPay":
      payLogo = HAMAPAY;
      break;
    case "Visa":
      payLogo = VISA;
      break;
    case "MasterCard":
      payLogo = MASTERCARD;
      break;
    case "Sony Payment":
    case "ソニーペイキャリア":
    case "ソニーペイコンビニ":
    //240703 兼容コンビニ決済
    case "コンビニ決済":
      //end
      payLogo = SonyPayment;
      break;

    case "PayPal":
      payLogo = PAYPAL;
      break;
    case "e街ギフト":
      payLogo = EGIFTEE;
      break;
    case "GoToEat":
      payLogo = GOTOEAT;
      break;
    case "Money Tap":
      payLogo = MONEYTAP;
      break;
    case "GEA":
      payLogo = GEAPAY;
      break;
    case "Swish":
      payLogo = SWISHPAY;
      break;
    case "BKM express":
      payLogo = BKMEXSPAY;
      break;
    case "KBZ Bank":
      payLogo = KBZBANKPAY;
      break;
    case "Sberbank":
      payLogo = SBERBANKPAY;
      break;
    case "TrueMoney":
      payLogo = TRUEMONEY;
      break;
    case "WingPay":
      payLogo = WINGPAY;
      break;
    case "MBBank":
      payLogo = MBBANKPAY;
      break;
    case "2c2p":
      payLogo = _2C2P;
      break;
    case "Pivo":
      payLogo = PIVOPAY;
      break;
    case "Alipay":
      payLogo = ALIPAYPLUS;
      break;
    case "PayPay":
      payLogo = PAYPAY;
      break;
    case "d払い":
      payLogo = DPAY;
      break;
    case "au PAY":
      payLogo = AUPAY;
      break;
    case "LINE Pay":
      payLogo = LINEPAY;
      break;
    case "WeChat Pay":
      payLogo = WECHATPAY;
      break;
    case "楽天ペイ":
      payLogo = RAKUTENPAY;
      break;
    case "銀聯QRコード決済":
      payLogo = UNIONPAY;
      break;
    case "QUOカードPay":
      payLogo = QUOPAY;
      break;
    case "J-Coin Pay":
      payLogo = JCOINPAY;
      break;
    case "GLN":
      payLogo = GLNPAY;
      break;
    case "JKOPAY":
      payLogo = JKOPAY;
      break;
    case "BANKIT":
      payLogo = BANKITPAY;
      break;
    case "Smart Code":
      payLogo = SMARTCODE;
      break;
    case "Bank Pay":
      payLogo = BANKPAY;
      break;
    case "AFTEEPAY":
      payLogo = AFTEEPAY;
      break;
    case "atone":
      payLogo = ATONEPAY;
      break;
    case "AEON Pay":
      payLogo = AEONPAY;
      break;
    case "FamiPay":
      payLogo = FAMIPAY;
      break;
    case "VERITRANSCARD":
      payLogo = VERITRANSCARD;
      break;
    case "VeriTrans":
      payLogo = VERITRANSCARD;
      break;
    case "UnionCard":
      payLogo = UNIONPAY;
      break;
    case "Origami Pay":
      payLogo = ORIGAMIPAY;
      break;
    case "メルペイ":
      payLogo = MERPAY;
      break;
    case "MobilePay":
      payLogo = MOBILEPAY;
      break;
    case "Bluecode":
      payLogo = BLUECODEPAY;
      break;
    case "銀行Pay":
    case "OKIPAY":
      payLogo = GINKOPAY;
      break;
    //230629增加POINT积分
    case "Ponta ポイント":
      payLogo = PONTAPOINT;
      break;
    //end
    //230724增加SPPNSS
    case "残高払い":
      payLogo = SPPNSS;
      break;
    //end
    //240105增加NCPI
    case "NPCIQNBMPM":
    case "NPCIPAY":
    case "NPCI":
      payLogo = NCPI;
      break;
    //end
    //240701拆分sonylogo
    case "ソニーペイクレジットカード":
      payLogo = CREDITOM;
      break;
    //end
    default:
      payLogo = STARPAY;
  }
  return payLogo;
}

export { getPayLogoByTradeType, getBigPayLogoByTradeTypeName, getNormalPayLogoByTradeTypeName };
